import DromoUploader, { IDeveloperField } from "dromo-uploader-react";
import { Region } from "../App";
import { Tooltip } from "react-tooltip";
import { IPositionSpec } from "dromo-uploader-js";

const orgClueWords = new Set([
  "co",
  "co.",
  "and",
  "pa",
  "lp",
  "md",
  "inc",
  "inc.",
  "pc",
  "the",
  "lab",
]);
const orgClueStrings = [
  "llc",
  "corp",
  "trust",
  "hoa",
  "&",
  "limited",
  "ltd",
  "incorporated",
  "pty",
  "llp",
  "superannuation",
  "fund",
  "church",
  "company",
  "corporation",
  "foundation",
  "association",
  "institution",
  "union",
  "agency",
  "board",
  "club",
  "group",
  "partnership",
  "partner",
  "society",
  "syndicate",
  "committee",
  "council",
  "alliance",
  "network",
  "holding",
  "management",
  "services",
  "enterprise",
  "industry",
  "industries",
  "solution",
  "technology",
  "technologies",
  "system",
  "resource",
  "consulting",
  "international",
  "global",
  "plc",
  "npo",
  "ngo",
  "charity",
  "cooperative",
  "consortium",
  "venture",
  "realty",
  "trustee",
  "municipality",
  "bureau",
  "authority",
  "syndicate",
  "institute",
  "capital",
  "investment",
  "financial",
  "media",
  "entertainment",
  "production",
  "logistic",
  "transport",
  "communication",
  "publishing",
  "ventures",
  "university",
  "college",
  "school",
  "academy",
  "healthcare",
  "medical",
  "insurance",
  "bank",
  "credit union",
  "research",
  "development",
  "construction",
  "estate",
  "property",
  "equipment",
  "consultant",
  "associate",
  "cpa",
  "assoc",
  "service",
  "professional",
  "health",
  "public",
  "advisor",
  "home",
  "investment",
  "dds",
  "pc",
  "properties",
  "irrev tr",
  "fbo",
  "chiropractic",
  "dba",
  "surgery",
  "center",
  "accountant",
  "trading",
  "clinic",
  "consultancy",
  "project",
];

const isProbablyOrg = (clientName: string): boolean => {
  // Set Person / Org, Person Full Name, Org Name
  const lowerCasedName = clientName.toLowerCase();
  const containsOrgClue = orgClueStrings.some(
    (orgClue) => lowerCasedName.indexOf(orgClue) !== -1
  );
  const hasStandaloneClue = lowerCasedName
    .split(" ")
    .some((nameWord) => orgClueWords.has(nameWord));
  const containsNumber = /\d/.test(clientName);
  const containsApostrophe = /'/.test(clientName);
  return (
    containsOrgClue || hasStandaloneClue || containsNumber || containsApostrophe
  );
};

const coreFields: IDeveloperField[] = [
  {
    label: "Client Name",
    key: "Client Name",
    description: "Could be either a person or organziation",
    validators: [{ validate: "required" }],
  },
  {
    label: "Organization Name",
    key: "Organization Name",
  },
  {
    label: "Organization Email",
    key: "Organization Email",
    type: "email",
  },
  {
    label: "Person Full Name",
    key: "Person Full Name",
  },
  {
    label: "Person First Name",
    key: "Person First Name",
  },
  {
    label: "Person Middle Name",
    key: "Person Middle Name",
  },
  {
    label: "Person Last Name",
    key: "Person Last Name",
  },
  {
    label: "Person DOB",
    key: "Person DOB",
    alternateMatches: ["Date of Birth"],
    type: "date",
  },
  { key: "Person Email", label: "Person Email", type: "email" },
  {
    key: "Person Work",
    label: "Person Work",
    validators: [{ validate: "length", max: 20 }],
  },
  {
    key: "Person Mobile",
    label: "Person Mobile",
    validators: [{ validate: "length", max: 20 }],
  },
  {
    key: "Person Fax",
    label: "Person Fax",
    validators: [{ validate: "length", max: 20 }],
  },
  {
    key: "Person Home",
    label: "Person Home",
    validators: [{ validate: "length", max: 20 }],
  },
  {
    key: "Person Other",
    label: "Person Other",
    validators: [{ validate: "length", max: 20 }],
  },
  {
    key: "Website",
    label: "Website",
    validators: [
      {
        validate: "regex_match",
        regex:
          /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[^\s@]*)?$/,
        errorMessage: "Invalid website",
      },
    ],
  },
  {
    label: "Contact Type",
    key: "Contact Type",
    alternateMatches: ["Client Type"],
  },
  {
    label: "Client Identifier",
    key: "Client Identifier",
  },
  {
    label: "Client Owner",
    key: "Client Owner",
  },
  {
    label: "Client Manager",
    key: "Client Manager",
  },
  { key: "Description", label: "Description" },
  {
    key: "Fiscal Year End Date",
    label: "Fiscal Year End Date",
    type: "date",
  },
  {
    key: "Fiscal Year End Month",
    label: "Fiscal Year End Month",
    type: "select",
    selectOptions: [
      {
        value: "1",
        label: "1",
        alternateMatches: ["Jan", "January"],
      },
      {
        value: "2",
        label: "2",
        alternateMatches: ["Feb", "February"],
      },
      {
        value: "3",
        label: "3",
        alternateMatches: ["Mar", "March"],
      },
      {
        value: "4",
        label: "4",
        alternateMatches: ["Apr", "April"],
      },
      {
        value: "5",
        label: "5",
        alternateMatches: ["May"],
      },
      {
        value: "6",
        label: "6",
        alternateMatches: ["Jun", "June"],
      },
      {
        value: "7",
        label: "7",
        alternateMatches: ["Jul", "July"],
      },
      {
        value: "8",
        label: "8",
        alternateMatches: ["Aug", "August"],
      },
      {
        value: "9",
        label: "9",
        alternateMatches: ["Sep", "September"],
      },
      {
        value: "10",
        label: "10",
        alternateMatches: ["Oct", "October"],
      },
      {
        value: "11",
        label: "11",
        alternateMatches: ["Nov", "November"],
      },
      {
        value: "12",
        label: "12",
        alternateMatches: ["Dec", "December"],
      },
    ],
  },
  { key: "Fiscal Year End Day", label: "Fiscal Year End Day" },
  {
    key: "Office",
    label: "Office",
    validators: [{ validate: "length", max: 20 }],
  },
  { key: "Fax", label: "Fax", validators: [{ validate: "length", max: 20 }] },
  {
    key: "Mobile",
    label: "Mobile",
    validators: [{ validate: "length", max: 20 }],
  },
  { key: "Home", label: "Home", validators: [{ validate: "length", max: 20 }] },
  {
    key: "Other Phone",
    label: "Other Phone",
    validators: [{ validate: "length", max: 20 }],
  },
  {
    key: "Physical Address Lines",
    label: "Physical Address Lines",
  },
  {
    key: "Physical Address City",
    label: "Physical Address City",
  },
  {
    key: "Physical Address State",
    label: "Physical Address State",
  },
  {
    key: "Physical Address Zip",
    label: "Physical Address Zip",
  },
  {
    key: "Physical Address Country",
    label: "Physical Address Country",
  },
  {
    key: "Mailing Address Lines",
    label: "Mailing Address Lines",
  },
  {
    key: "Mailing Address City",
    label: "Mailing Address City",
  },
  {
    key: "Mailing Address State",
    label: "Mailing Address State",
  },
  {
    key: "Mailing Address Zip",
    label: "Mailing Address Zip",
  },
  {
    key: "Mailing Address Country",
    label: "Mailing Address Country",
  },
  {
    key: "Related Group (Client Group)",
    label: "Related Group (Client Group)",
  },
];

const americasAcctFields: IDeveloperField[] = [
  {
    key: "(US Accounting) Tax ID",
    label: "(US Accounting) Tax ID",
    validators: [{ validate: "length", max: 50 }],
  },
  {
    key: "(US Accounting) Social Security Number",
    label: "(US Accounting) Social Security Number",
    validators: [{ validate: "length", max: 50 }],
  },
  {
    key: "(US Accounting) Entity Type",
    label: "(US Accounting) Entity Type",
    type: "select",
    selectOptions: [
      { value: "Corporation", label: "Corporation" },
      { value: "Estate or Trust", label: "Estate or Trust" },
      { value: "S Corporation", label: "S Corporation" },
      { value: "Exempt Organization", label: "Exempt Organization" },
      { value: "Partnership", label: "Partnership" },
      { value: "Sole Proprietor", label: "Sole Proprietor" },
      { value: "Club or Society", label: "Club or Society" },
      { value: "Limited Liability", label: "Limited Liability" },
      { value: "Other/None", label: "Other/None" },
      { value: "Person", label: "Person" },
      { value: "Private Foundation", label: "Private Foundation" },
    ],
  },
  {
    key: "(US Accounting) Services",
    label: "(US Accounting) Services",
    validators: [{ validate: "length", max: 50 }],
  },
  {
    key: "(US Accounting) Tax Notes",
    label: "(US Accounting) Tax Notes",
    validators: [{ validate: "length", max: 50 }],
  },
  {
    key: "(US Accounting) Basic Notes",
    label: "(US Accounting) Basic Notes",
    validators: [{ validate: "length", max: 50 }],
  },
];
const ausAcctFields: IDeveloperField[] = [
  {
    key: "(AU Accounting) Australian Business Number (ABN)",
    label: "(AU Accounting) Australian Business Number (ABN)",
    validators: [{ validate: "length", max: 50 }],
  },
  {
    key: "(AU Accounting) Australian Company Number (ACN)",
    label: "(AU Accounting) Australian Company Number (ACN)",
    validators: [{ validate: "length", max: 50 }],
  },
  {
    key: "(AU Accounting) Tax File Number (TFN)",
    label: "(AU Accounting) Tax File Number (TFN)",
    validators: [{ validate: "length", max: 50 }],
  },
  {
    key: "(AU Accounting) Entity Type",
    label: "(AU Accounting) Entity Type",
    type: "select",
    selectOptions: [
      { value: "Club or Society", label: "Club or Society" },
      { value: "Not for Profit", label: "Not for Profit" },
      { value: "Partnership", label: "Partnership" },
      { value: "Company", label: "Company" },
      { value: "Person", label: "Person" },
      {
        value: "Self Managed Superannuation Fund",
        label: "Self Managed Superannuation Fund",
      },
      { value: "Sole Proprietor", label: "Sole Proprietor" },
      { value: "Superannuation Fund", label: "Superannuation Fund" },
      { value: "Trust", label: "Trust" },
    ],
  },
  {
    key: "(AU Accounting) Services",
    label: "(AU Accounting) Services",
    validators: [{ validate: "length", max: 50 }],
  },
  {
    key: "(AU Accounting) Tax Notes",
    label: "(AU Accounting) Tax Notes",
    validators: [{ validate: "length", max: 50 }],
  },
  {
    key: "(AU Accounting) Basic Notes",
    label: "(AU Accounting) Basic Notes",
    validators: [{ validate: "length", max: 50 }],
  },
];
const canadaAcctFields: IDeveloperField[] = [
  {
    key: "(CA Accounting) Business Number",
    label: "(CA Accounting) Business Number",
    validators: [{ validate: "length", max: 50 }],
  },
  {
    key: "(CA Accounting) Social Insurance Number (SIN)",
    label: "(CA Accounting) Social Insurance Number (SIN)",
    validators: [{ validate: "length", max: 50 }],
  },
  {
    key: "(CA Accounting) Entity Type",
    label: "(CA Accounting) Entity Type",
    type: "select",
    selectOptions: [
      { value: "Club or Society", label: "Club or Society" },
      { value: "Corporation", label: "Corporation" },
      { value: "Not for Profit", label: "Not for Profit" },
      { value: "Partnership", label: "Partnership" },
      { value: "Person", label: "Person" },
      { value: "Sole Proprietorship", label: "Sole Proprietorship" },
      { value: "Trust", label: "Trust" },
    ],
  },
  {
    key: "(CA Accounting) Services",
    label: "(CA Accounting) Services",
    validators: [{ validate: "length", max: 50 }],
  },
  {
    key: "(CA Accounting) Tax Notes",
    label: "(CA Accounting) Tax Notes",
    validators: [{ validate: "length", max: 50 }],
  },
  {
    key: "(CA Accounting) Basic Notes",
    label: "(CA Accounting) Basic Notes",
    validators: [{ validate: "length", max: 50 }],
  },
];
const ukAcctFields: IDeveloperField[] = [
  {
    key: "(UK Accounting) VAT Number",
    label: "(UK Accounting) VAT Number",
    validators: [{ validate: "length", max: 50 }],
  },
  {
    key: "(UK Accounting) Company Registration Number",
    label: "(UK Accounting) Company Registration Number",
    validators: [{ validate: "length", max: 50 }],
  },
  {
    key: "(UK Accounting) National Insurance Number",
    label: "(UK Accounting) National Insurance Number",
    validators: [{ validate: "length", max: 50 }],
  },
  {
    key: "(UK Accounting) PAYE Reference Number",
    label: "(UK Accounting) PAYE Reference Number",
    validators: [{ validate: "length", max: 50 }],
  },
  {
    key: "(UK Accounting) PAYE Accounts Office Reference",
    label: "(UK Accounting) PAYE Accounts Office Reference",
    validators: [{ validate: "length", max: 50 }],
  },
  {
    key: "(UK Accounting) CT Reference",
    label: "(UK Accounting) CT Reference",
    validators: [{ validate: "length", max: 50 }],
  },
  {
    key: "(UK Accounting) Unique Taxpayer Reference (UTR)",
    label: "(UK Accounting) Unique Taxpayer Reference (UTR)",
    validators: [{ validate: "length", max: 50 }],
  },
  {
    key: "(UK Accounting) Entity Type",
    label: "(UK Accounting) Entity Type",
    type: "select",
    selectOptions: [
      {
        value: "Charitable Incorporated Organisation",
        label: "Charitable Incorporated Organisation",
      },
      { value: "Club or Society", label: "Club or Society" },
      {
        value: "Community Interest Company",
        label: "Community Interest Company",
      },
      { value: "Company", label: "Company" },
      {
        value: "Company Limited by Guarantee",
        label: "Company Limited by Guarantee",
      },
      { value: "Housing Association", label: "Housing Association" },
      { value: "Limited Company", label: "Limited Company" },
      {
        value: "Limited Liability Partnership",
        label: "Limited Liability Partnership",
      },
      { value: "Local Authority", label: "Local Authority" },
      { value: "Look Through Company", label: "Look Through Company" },
      {
        value: "Non Departmental Public Body",
        label: "Non Departmental Public Body",
      },
      { value: "Not for Profit", label: "Not for Profit" },
      { value: "Partnership", label: "Partnership" },
      { value: "Pension Scheme", label: "Pension Scheme" },
      { value: "Person", label: "Person" },
      { value: "Royal Charter", label: "Royal Charter" },
      { value: "Sole Proprietor", label: "Sole Proprietor" },
      { value: "Trust", label: "Trust" },
      {
        value: "Unincorporated Association",
        label: "Unincorporated Association",
      },
      { value: "Unincorporated Charity", label: "Unincorporated Charity" },
    ],
  },
  {
    key: "(UK Accounting) Services",
    label: "(UK Accounting) Services",
    validators: [{ validate: "length", max: 50 }],
  },
  {
    key: "(UK Accounting) Charity Number",
    label: "(UK Accounting) Charity Number",
    validators: [{ validate: "length", max: 50 }],
  },
  {
    key: "(UK Accounting) Companies House",
    label: "(UK Accounting) Companies House",
    validators: [{ validate: "length", max: 50 }],
  },
  {
    key: "(UK Accounting) Tax Notes",
    label: "(UK Accounting) Tax Notes",
    validators: [{ validate: "length", max: 50 }],
  },
  {
    key: "(UK Accounting) Basic Notes",
    label: "(UK Accounting) Basic Notes",
    validators: [{ validate: "length", max: 50 }],
  },
];

const nzAcctFields: IDeveloperField[] = [
  {
    key: "(NZ Accounting) Business Number",
    label: "(NZ Accounting) Business Number",
    validators: [{ validate: "length", max: 50 }],
  },
  {
    key: "(NZ Accounting) GST Number",
    label: "(NZ Accounting) GST Number",
    validators: [{ validate: "length", max: 50 }],
  },
  {
    key: "(NZ Accounting) IRD Number",
    label: "(NZ Accounting) IRD Number",
    validators: [{ validate: "length", max: 50 }],
  },
  {
    key: "(NZ Accounting) Entity Type",
    label: "(NZ Accounting) Entity Type",
    type: "select",
    selectOptions: [
      { value: "Charity", label: "Charity" },
      { value: "Club or Society", label: "Club or Society" },
      { value: "Look-Through Company", label: "Look-Through Company" },
      { value: "Company", label: "Company" },
      { value: "Partnership", label: "Partnership" },
      { value: "Person", label: "Person" },
      { value: "Sole Proprietor", label: "Sole Proprietor" },
      { value: "Trust", label: "Trust" },
    ],
  },
  {
    key: "(NZ Accounting) Services",
    label: "(NZ Accounting) Services",
    validators: [{ validate: "length", max: 50 }],
  },
  {
    key: "(NZ Accounting) Tax Notes",
    label: "(NZ Accounting) Tax Notes",
    validators: [{ validate: "length", max: 50 }],
  },
  {
    key: "(NZ Accounting) Basic Notes",
    label: "(NZ Accounting) Basic Notes",
    validators: [{ validate: "length", max: 50 }],
  },
];
const regionalAcctFields: Record<Region, IDeveloperField[]> = {
  US: americasAcctFields,
  Australia: ausAcctFields,
  Canada: canadaAcctFields,
  UK: ukAcctFields,
  NZ: nzAcctFields,
  Other: americasAcctFields
    .concat(ukAcctFields)
    .concat(canadaAcctFields)
    .concat(ausAcctFields)
    .concat(nzAcctFields),
};
const allFields = coreFields.concat(regionalAcctFields.Other);

const regionalDateSettings: Record<
  Region,
  { displayFormat: string; outputFormat: string }
> = {
  US: { displayFormat: "M/d/yyyy", outputFormat: "MM/dd/yyyy" },
  Canada: { displayFormat: "M/d/yyyy", outputFormat: "MM/dd/yyyy" },
  Other: { displayFormat: "M/d/yyyy", outputFormat: "MM/dd/yyyy" },
  UK: { displayFormat: "d/M/yyyy", outputFormat: "MM/dd/yyyy" },
  Australia: { displayFormat: "d/M/yyyy", outputFormat: "MM/dd/yyyy" },
  NZ: { displayFormat: "d/M/yyyy", outputFormat: "MM/dd/yyyy" },
};
export const fieldOrder = ["Uploader Email", "Person/Org"].concat(
  allFields.map((field) => field.key)
);
export const Uploader = (props: {
  setResults: (data: any[]) => void;
  userEmail?: string;
  region?: Region;
}) => {
  const { region, userEmail } = props;
  const disabled = userEmail === undefined || region === undefined;

  if (disabled) {
    return (
      <div>
        <Tooltip id="disabled-tooltip">
          Enter email address and select region first!
        </Tooltip>
        <button
          data-tooltip-id="disabled-tooltip"
          disabled={disabled}
          className="px-6 pt-3.5 pb-3.5 text-base  bg-[royalblue] hover:bg-[midnightblue] rounded-xl text-white shadow overflow-hidden text-center disabled:bg-[grey]"
        >
          Import Bulk Data
        </button>
      </div>
    );
  }
  const fields = coreFields.concat(regionalAcctFields[region]);
  fields.forEach((field) => {
    if (field.type === "date") {
      field.type = ["date", regionalDateSettings[region]];
    }
  });
  return (
    <DromoUploader
      // Local Key
      // licenseKey="414617ed-5db9-4b84-b9a4-36c8c643914f"
      // Karbon Key
      licenseKey="f3b77875-8d2a-4380-99d8-5c3cd6a697e7"
      fields={fields}
      settings={{
        importIdentifier: "Contacts",
        developmentMode:
          new URLSearchParams(window.location.search).get("dev") === "true",
        autoMapHeaders: true,
        backendSyncMode: "FULL_DATA",
        browserExcelParsing: true,
        invalidDataBehavior: "INCLUDE_INVALID_ROWS",
        reviewStep: { enableUserTransformations: true },
      }}
      user={{ email: userEmail, id: "Karbon" }}
      rowHooks={[
        (record, mode) => {
          const newRecord = record;
          const personOrgCell = newRecord.row["Person/Org"];
          const clientNameCell = newRecord.row["Client Name"];
          const fullNameCell = newRecord.row["Person Full Name"];
          const orgNameCell = newRecord.row["Organization Name"];

          if (mode === "init") {
            if (record.index === 0) {
              record.row["Uploader Email"].value = userEmail;
            }

            // Set Person / Org, Person Full Name, Org Name
            const clientType = isProbablyOrg(clientNameCell.value)
              ? "Org"
              : "Person";
            personOrgCell.value = clientType;

            if (clientType === "Person" && !fullNameCell.value) {
              fullNameCell.value = clientNameCell.value;
            } else if (clientType === "Org" && !orgNameCell.value) {
              orgNameCell.value = clientNameCell.value;
            }
          } else {
            // if we switch from Person to Org, clear out full name, first/middle/last and put full name into organization name
            if (personOrgCell.value === "Org") {
              if (clientNameCell.value === fullNameCell.value) {
                fullNameCell.value = "";
                newRecord.row["Person First Name"].value = "";
                newRecord.row["Person Last Name"].value = "";
              }
              if (!orgNameCell.value) {
                orgNameCell.value = clientNameCell.value;
              }
            }
            // if we switch from Org to Person, clear out organization name (if its the same as client name), set client name to organization name
            if (personOrgCell.value === "Person") {
              if (clientNameCell.value === orgNameCell.value) {
                orgNameCell.value = "";
              }
              if (!fullNameCell.value) {
                fullNameCell.value = clientNameCell.value;
              }
            }
          }
          // Set First Name and Last Name
          let firstName = newRecord.row["Person First Name"].value;
          let middleName = newRecord.row["Person Middle Name"].value;
          let lastName = newRecord.row["Person Last Name"].value;
          const fullName = fullNameCell.value;
          if (fullName && !firstName && !lastName && !middleName) {
            const commaIndex = fullName.indexOf(",");
            if (commaIndex !== -1) {
              [firstName, lastName] = fullName.split(",").reverse();
            } else {
              const nameWords = fullName.split(" ");
              if (nameWords.length < 3) {
                [firstName, lastName] = nameWords;
              } else {
                [firstName, middleName, lastName] = [
                  nameWords[0],
                  nameWords[1],
                  nameWords.slice(2).join(" "),
                ];
              }
            }

            newRecord.row["Person First Name"].value = firstName;
            newRecord.row["Person Middle Name"].value = middleName;
            newRecord.row["Person Last Name"].value = lastName;
          }

          // Fiscal year
          const fyEndDate = newRecord.row["Fiscal Year End Date"];
          const fyEndMonth = newRecord.row["Fiscal Year End Month"];
          const fyEndDay = newRecord.row["Fiscal Year End Day"];

          // if we have date, pull out month and day
          // we should do this not just on init
          if (fyEndDate?.value) {
            const parsedEndDate = new Date(fyEndDate.value);
            fyEndMonth.value = (parsedEndDate.getMonth() + 1).toString();
            fyEndDay.value = parsedEndDate.getDate();
          }

          // if month is "january" -> map to 1-12 int
          if (fyEndMonth?.value && !fyEndDay?.value) {
            fyEndDay.value = new Date(
              new Date().getFullYear(),
              fyEndMonth.value - 1,
              0
            ).getDate();
          }

          return newRecord;
        },
      ]}
      onResults={(response, metadata) => {
        props.setResults(response);
      }}
      stepHooks={[
        {
          type: "REVIEW_STEP",
          callback: (importer, data: any) => {
            const addedFields: Set<string> = new Set();

            const addField = (
              field: IDeveloperField,
              position?: IPositionSpec
            ) => {
              if (addedFields.has(field.key)) return;
              addedFields.add(field.key);
              importer.addField(field, position);
            };

            addField(
              {
                label: "Person/Org",
                key: "Person/Org",
                type: "select",
                selectOptions: [
                  { value: "Person", label: "Person" },
                  { value: "Org", label: "Org" },
                ],
              },
              { before: "Client Name" }
            );
            addField(
              {
                label: "Uploader Email",
                key: "Uploader Email",
                hidden: true,
              },
              { before: "Person/Org" }
            );
            if (!data.fields.hasOwnProperty("Organization Name")) {
              addField(
                {
                  label: "Organization Name",
                  key: "Organization Name",
                  validators: [
                    {
                      validate: "require_with_values",
                      fieldValues: { "Person/Org": "Org" },
                    },
                  ],
                },
                { after: "Client Name" }
              );
            }
            if (!data.fields.hasOwnProperty("Person Full Name")) {
              addField(
                {
                  label: "Person Full Name",
                  key: "Person Full Name",
                  validators: [
                    {
                      validate: "require_with_values",
                      fieldValues: { "Person/Org": "Person" },
                    },
                  ],
                },
                { after: "Organization Name" }
              );
            }
            if (!data.fields.hasOwnProperty("Person First Name")) {
              addField(
                {
                  label: "Person First Name",
                  key: "Person First Name",
                },
                { after: "Person Full Name" }
              );
            }
            if (!data.fields.hasOwnProperty("Person Middle Name")) {
              addField(
                {
                  label: "Person Middle Name",
                  key: "Person Middle Name",
                },
                { after: "Person First Name" }
              );
            }
            if (!data.fields.hasOwnProperty("Person Last Name")) {
              addField(
                {
                  label: "Person Last Name",
                  key: "Person Last Name",
                },
                { after: "Person Middle Name" }
              );
            }
            if (data.fields.hasOwnProperty("Fiscal Year End Date")) {
              if (!data.fields.hasOwnProperty("Fiscal Year End Month")) {
                addField(
                  {
                    label: "Fiscal Year End Month",
                    key: "Fiscal Year End Month",
                    type: "select",
                    selectOptions: [
                      {
                        value: "1",
                        label: "1",
                        alternateMatches: ["Jan", "January"],
                      },
                      {
                        value: "2",
                        label: "2",
                        alternateMatches: ["Feb", "February"],
                      },
                      {
                        value: "3",
                        label: "3",
                        alternateMatches: ["Mar", "March"],
                      },
                      {
                        value: "4",
                        label: "4",
                        alternateMatches: ["Apr", "April"],
                      },
                      {
                        value: "5",
                        label: "5",
                        alternateMatches: ["May"],
                      },
                      {
                        value: "6",
                        label: "6",
                        alternateMatches: ["Jun", "June"],
                      },
                      {
                        value: "7",
                        label: "7",
                        alternateMatches: ["Jul", "July"],
                      },
                      {
                        value: "8",
                        label: "8",
                        alternateMatches: ["Aug", "August"],
                      },
                      {
                        value: "9",
                        label: "9",
                        alternateMatches: ["Sep", "September"],
                      },
                      {
                        value: "10",
                        label: "10",
                        alternateMatches: ["Oct", "October"],
                      },
                      {
                        value: "11",
                        label: "11",
                        alternateMatches: ["Nov", "November"],
                      },
                      {
                        value: "12",
                        label: "12",
                        alternateMatches: ["Dec", "December"],
                      },
                    ],
                  },
                  { after: "Fiscal Year End Date" }
                );
              }
              if (!data.fields.hasOwnProperty("Fiscal Year End Day")) {
                addField(
                  {
                    label: "Fiscal Year End Day",
                    key: "Fiscal Year End Day",
                    type: ["number", { preset: "integer", min: 1, max: 31 }],
                  },
                  { after: "Fiscal Year End Month" }
                );
              }
            }
            allFields.forEach((outputField) => {
              if (!data.fields.hasOwnProperty(outputField.key)) {
                addField({
                  key: outputField.key,
                  label: outputField.label,
                  hidden: false,
                });
              }
            });
          },
        },
      ]}
      beforeFinish={(data) => {
        const missingClientNames = data
          .map((r, rowIndex) => (!r["Client Name"] ? rowIndex + 1 : null))
          .filter((v) => v !== null);
        if (missingClientNames.length > 0) {
          return {
            cancel: true,
            message: `All records must contain a client name. Please fill in missing client names or delete all rows with missing client names. Affected rows: ${missingClientNames.join(
              ", "
            )}`,
          };
        }
      }}
    >
      <div
        data-tooltip-id="disabled-tooltip"
        className="px-6 pt-3.5 pb-3.5 text-base  bg-[royalblue] hover:bg-[midnightblue] rounded-xl text-white shadow overflow-hidden text-center"
      >
        Import Bulk Data
      </div>
    </DromoUploader>
  );
};
