import { useState } from "react";
import "./Styles/App.css";
import { fieldOrder, Uploader } from "./Components/Uploader";
import Papa from "papaparse";

export type Region = "US" | "Canada" | "UK" | "Australia" | "NZ" | "Other";
const regions: Region[] = ["US", "Canada", "UK", "Australia", "NZ", "Other"];

export default function App() {
  const [uploadData, setUploadData] = useState<any[]>([]);
  const [userEmail, setUserEmail] = useState<string | undefined>();
  const [region, setRegion] = useState<Region | undefined>();
  let csvBlob: Blob | undefined;
  const devMode =
    new URLSearchParams(window.location.search).get("dev") === "true";
  if (uploadData.length > 0) {
    const csv = Papa.unparse(uploadData, {
      quotes: true,
      columns: fieldOrder,
    });

    csvBlob = new Blob([csv], { type: "text/csv" });
  }

  return (
    <div className="py-4 px-4 flex justify-center">
      <div className="rounded-lg shadow bg-white max-w-3xl min-w-[768px]">
        <div className="border-b px-4 py-5 sm:px-6 ">
          <div className="min-w-0 ml-4 mt-2">
            <h3 className="text-base font-semibold leading-6 text-gray-900 flex justify-between">
              <div>Karbon Data Importer </div>
              {devMode ? (
                <div className="bg-green-400 px-1 rounded-md shadow">
                  {" "}
                  DEVELOPMENT MODE
                </div>
              ) : null}
            </h3>
          </div>
        </div>
        <div className="px-4 py-5 sm:p-6 col-span-2 row-start-2 min-w-0 text-xl text-gray-500">
          <ol className="text-2xl list-inside space-y-6">
            <li>
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="email"
              >
                Email Address
              </label>
              <input
                type="email"
                name="email"
                id="email"
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                onChange={(e) => setUserEmail(e.target.value)}
                value={userEmail ?? ""}
                placeholder="your email address..."
              />
            </li>
            <li>
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="region"
              >
                Region
              </label>
              <select
                name="region"
                id="region"
                onChange={(e) => setRegion(e.currentTarget.value as Region)}
                value={region}
                className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              >
                <option>Select your region...</option>
                {regions.map((region) => (
                  <option value={region}>{region}</option>
                ))}
              </select>
            </li>
            <div>
              <div className="py-3 flex justify-center">
                <Uploader
                  setResults={setUploadData}
                  userEmail={userEmail}
                  region={region}
                />
              </div>
            </div>
          </ol>

          <br />
          {/* {uploadData.length > 0
            ? `Upload complete: ${uploadData.length} rows`
            : "No upload yet..."} */}
          <div className="flex justify-center">
            {csvBlob ? (
              <a
                href={window.URL.createObjectURL(csvBlob)}
                download={"bulk_data.csv"}
                className="text-blue-600 underline"
              >
                Download CSV
              </a>
            ) : (
              "Once import is complete, CSV link will be here"
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
